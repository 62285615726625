<!-- eslint-disable no-unused-vars -->
<script setup>
import Layout from '../../layouts/main.vue'
import PageHeader from '@/components/page-header'
import { callApi } from '../../../msal/msalApi';
import { reactive, onMounted, onBeforeMount, defineProps } from 'vue';
import { useRoute } from 'vue-router'
// eslint-disable-next-line no-unused-vars
import Swal from 'sweetalert2'

import { Store, useStore } from "vuex";

const store = useStore()

const querys = useRoute().query

const props = defineProps({
    franchiseInvoiceID: String,
    calendarDate: String,
    button: String
});

let data = reactive({
    loadData: false,
    url: '',
    email: '',
    selectDate: '',
    invoiceDate: '',
    count: 0,
    totalAmountNewly: 0,
    totalAmountMonitored: 0,
    totalAmountDeactivated: 0,
    totalAmountAll: 0,
    totalLocation: 0,
    dataBilling: [],
    InvoiceSummary: [],
    all: [],
    newly: [],
    monitored: [],
    deactivated: [],
    availableInvoices: [],
    franchiseInvoiceSummaries: [],

    list: [],
});


async function getPracticeGroup() {
    data.loadData = true
    data.url = `${process.env.VUE_APP_WLT_API}Associate/PracticeGroupMembersPaged?email=${store.getters.getEmail}&sortByList[0].Name=FranchiseName&sortByList[0].Direction=Asc&sortByList[1].Name=LastName&sortByList[1].Direction=Desc`;
    // eslint-disable-next-line no-undef
    const userData = `${process.env.VUE_APP_WLT_API}Client/InfoGet?email=${data.email}`;
    const billings = `  ${process.env.VUE_APP_WLT_API}Billing/Invoice?invoiceDate=${querys.date || data.selectDate}&FranchiseID=${querys.franchise_ID || props.franchiseInvoiceID.franchise_ID}`;
    const billingsDate = `${process.env.VUE_APP_WLT_API}Billing/MonitoringInvoiceDates?franchiseID=${querys.franchise_ID}`
    const auditInfo = `${process.env.VUE_APP_WLT_API}Billing/AuditInfo?userID=FE9BBF2F-5AA2-4AAF-A962-0001D982C559`

    try {

        const [response, response2, billing, dateList] = await Promise.all([
            callApi(data.url, store.getters.getToken),
            callApi(userData, store.getters.getToken),
            callApi(billings, store.getters.getToken),
            callApi(billingsDate, store.getters.getToken)

        ]);

        // Asignar dataBilling a data
        data.dataBilling = billing;
        data.InvoiceSummary = data.dataBilling.franchiseInvoiceSummary

        // Filtrar los elementos por tipos
        data.newly = data.dataBilling.lineItems.filter(item => item.billingGroup === "NEWLY_ACTIVATED");
        data.monitored = data.dataBilling.lineItems.filter(item => item.billingGroup === "MONITORED");
        data.deactivated = data.dataBilling.lineItems.filter(item => item.billingGroup === "DEACTIVATED");

        // Calcular el total de los montos
        let totalAmountAll = 0;
        data.dataBilling.lineItems.forEach(amount => {
            totalAmountAll += amount.monitoringRate;
        });

        let totalAmountNewly = data.newly.reduce((sum, amount) => sum + amount.monitoringRate, 0);
        let totalAmountMonitored = data.monitored.reduce((sum, amount) => sum + amount.monitoringRate, 0);
        let totalAmountDeactivated = data.deactivated.reduce((sum, amount) => sum + amount.monitoringRate, 0);

        data.totalAmountNewly = totalAmountNewly.toFixed(2)
        data.totalAmountAll = totalAmountAll.toFixed(2)
        data.totalAmountMonitored = totalAmountMonitored.toFixed(2)
        data.totalAmountDeactivated = totalAmountDeactivated.toFixed(2)



        data.totalLocation = data.dataBilling.lineItems.length

        data.list = dateList.invoiceDates
        data.selectDate = formatearFecha( querys.date)

        console.log(data.dataBilling)
        data.loadData = false;
    } catch (error) {
        console.error(error);
    }

}
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Meses son 0-indexados, así que suma 1 y agrega un 0 al principio si es necesario
    const day = date.getDate().toString().padStart(2, '0'); // Agrega un 0 al principio si es necesario

    return `${year}/${month}/${day}`;
};

const formatDate2 = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Meses son 0-indexados, así que suma 1 y agrega un 0 al principio si es necesario
    const day = date.getDate().toString().padStart(2, '0'); // Agrega un 0 al principio si es necesario

    return `${year}-${month}-${day}`;
};

const formatDateWithMonth = (dateString) => {
    const monthsInEnglish = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const date = new Date(dateString);
    const year = date.getFullYear();
    const monthIndex = date.getMonth(); // Meses son 0-indexados
    const month = monthsInEnglish[monthIndex];

    return `${month} ${year}`;
};

function formatearFecha(fecha) {
    const partes = fecha.split(/[/-]/); // Usar una expresión regular para dividir por "/" o "-"
    if (partes.length === 3) {
        const [ano, mes, dia] = partes;
        const fechaFormateada = `${ano}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}T00:00:00`;
        return fechaFormateada;
    } else {
        return "Formato de fecha no válido";
    }
}

function filterDate() {
    console.log(formatDate(data.selectDate))
    querys.date = formatDate2(data.selectDate)

    getPracticeGroup()
}


async function showInfo(userID) {
    console.log(userID);
    const secondApiUrl = `${process.env.VUE_APP_WLT_API}Billing/AuditInfo?userID=${userID}`;

    try {
        // Realizamos la llamada a la segunda API
        const audit = await callApi(secondApiUrl, store.getters.getToken);

        let accordionHtml = ''; // Inicializamos una variable para acumular el contenido HTML del acordeón

        audit.forEach((auditInfo, index) => {
            console.log(auditInfo);

            accordionHtml += `
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse${index}" aria-expanded="false" aria-controls="collapse${index}">
                            ${formatDate(auditInfo.createDate)}
                        </button>
                    </h2>
                    <div id="collapse${index}" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <p><strong>Date:</strong> ${formatDate(auditInfo.createDate)}</p>
                            <p><strong>Name:</strong> ${auditInfo.clientFirstName} ${auditInfo.clientLastName}</p>
                            <p><strong>Action:</strong> ${auditInfo.monitoringAuditTypeDesc}</p>
                            <p><strong>By User:</strong> ${auditInfo.createdByUsername || ''}: ${auditInfo.createdByFirstName || ''} ${auditInfo.createdByLastName || ''}</p>
                            <p><strong>Monitored Until:</strong> ${auditInfo.monitoredUntilDate != null ? formatDate(auditInfo.monitoredUntilDate) : '---'}</p>
                        </div>
                    </div>
                </div>
            `;
        });

        Swal.fire({
            html: `
                <div class="container mt-4">
                    <div class="accordion" id="accordionExample">
                        ${accordionHtml}
                    </div>
                </div>
            `,
            showCloseButton: true,
            confirmButtonColor: '#FF0000', // Código hexadecimal del color rojo
        });

    } catch (error) {
        console.error('Error fetching audit info:', error);
    }
}


onBeforeMount(async () => {
    getPracticeGroup()
    const billingsDate = `${process.env.VUE_APP_WLT_API}Billing/MonitoringInvoiceDates?franchiseID=${querys.franchise_ID}`

    try {
        const [dateList] = await Promise.all([
            callApi(billingsDate, store.getters.getToken)
        ]);
        //data.selectDate = formatearFecha(querys.date)

        console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE")
        //console.log(dateList, querys,formatearFecha(formatDate))

    } catch (error) {
        alert(error)
        console.log(error)
    }
})

onMounted(async () => {
    //getPracticeGroup();
});
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
    </Layout>



    <div class="container  fs-5 text">

        <div class="spinner-overlay text-center mt-5" v-if="data.loadData">
            <div class="spinner-border text-primary  custom-spinner" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <div>Loading...</div>
        </div>

        <router-link v-if="props.button != false" class="mx-1 fw-bold" :to="{
            path: '/Billing/',
            query: { date: formatDate2(querys.date) }
        }">
            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24"
                style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;">
                <path d="M9 10h6c1.654 0 3 1.346 3 3s-1.346 3-3 3h-3v2h3c2.757 0 5-2.243 5-5s-2.243-5-5-5H9V5L4 9l5 4v-3z">
                </path>
            </svg> </router-link>

        <select class="form-select" v-model="data.selectDate" @change="filterDate">
            <option :value="date" v-for="(date, index) in data.list" :key="index">
                {{ formatDate(date) }}
            </option>
        </select>

        <div class="mb-5 h5  border border-light">

            <div class="table-responsive" v-if="!data.loadData">
                <table class="table caption-top table-light  table-striped table-hover text-center">
                    <caption class="text-center">
                        <p class="bg-dark pb-2 pt-2 text-white table-text h4">
                            {{ formatDateWithMonth(data.InvoiceSummary.calendarDate) }} Billing Report
                        </p>
                    </caption>
                    <caption class="">
                        <p class="pb-2 pt-2 text-dark table-text">
                            LICENSEE: {{ data.InvoiceSummary.ownerFirstName }} {{ data.InvoiceSummary.ownerLastName }}
                        </p>
                        <p class="pb-2 pt-2 text-dark table-text">
                            PRACTICE GROUP: {{ data.InvoiceSummary.franchiseName }}

                        </p>
                    </caption>

                    <div class="d-flex justify-content-between">
                        <P>
                            Client Count: {{ data.InvoiceSummary.clientCount }}
                        </P>

                        <p>
                            Total Billed: ${{ data.totalAmountAll }}

                        </p>
                    </div>

                    <tbody>


                    </tbody>
                </table>
            </div>

            <div class="table-responsive overflow-hidden" v-if="!data.loadData">
                <table class="table caption-top table-light  table-striped table-hover text-center">
                    <caption class="text-center">
                        <p class="bg-dark text-white h4 pb-2 pt-2 "> New diet program enrollments and newly monitored
                            clients</p>

                    </caption>
                    <thead class="">
                        <tr>
                            <th></th>
                            <th scope="col">Name</th>
                            <th scope="col">Billing Cycle</th>
                            <th scope="col">Currently Enrolled</th>
                            <th scope="col">Program Start</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(billing, index) in data.newly" :key="index">
                            <td>
                                <i @click="showInfo(billing.userAccountID)" class='bx bx-error-circle'></i>
                            </td>

                            <td>
                                <router-link class="mx-1" :to="{
                                    path: '/edit-client/' + billing.clientEmail + '/' + billing.franchise_ID,
                                    query: {
                                        fromBilling: true,
                                        dateBilling: formatDate(billing.calendarDate),
                                        franchise_ID: billing.franchise_ID
                                    }
                                }">
                                    {{ billing.firstName }} {{ billing.lastName }}

                                </router-link>
                            </td>

                            <td>{{ formatDate(billing.billingCycleStartDate) }} to {{
                                formatDate(billing.billingCycleEndDate) }}</td>
                            <td>WL {{ billing.subProgramDescription }}</td>
                            <td>{{ formatDate(billing.programStartDate) }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="d-flex justify-content-around">
                    <p>
                        Total Newly Activated: {{ data.newly.length }}
                    </p>
                    <p>
                        Rate:4.99
                    </p>
                    <p>
                        Amount: ${{ data.totalAmountNewly }}
                    </p>
                </div>
            </div>

            <div class="table-responsive" v-if="!data.loadData">
                <table class="table caption-top table-light table-striped table-hover text-center">
                    <caption class="text-center">
                        <p class="bg-dark text-white table-text h4 pb-2 pt-2 ">Monitored</p>
                    </caption>
                    <thead class="">
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Billing Cycle</th>
                            <th>Currently Enrolled</th>
                            <th>Program Start</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(billing, index) in data.monitored" :key="index">
                            <td>
                                <i @click="showInfo(billing.userAccountID)" class='bx bx-error-circle'></i>
                            </td>
                            <td>
                                <router-link class="mx-1" :to="{
                                    path: '/edit-client/' + billing.clientEmail + '/' + billing.franchise_ID,
                                    query: {
                                        fromBilling: true,
                                        dateBilling: formatDate(billing.calendarDate),
                                        franchise_ID: billing.franchise_ID
                                    }
                                }">
                                    {{ billing.firstName }} {{ billing.lastName }}

                                </router-link>
                            </td>
                            <td>{{ formatDate(billing.billingCycleStartDate) }} to {{
                                formatDate(billing.billingCycleEndDate) }}</td>
                            <td>WL {{ billing.subProgramDescription }}</td>
                            <td>{{ formatDate(billing.programStartDate) }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="d-flex justify-content-around">
                    <p> Monitoring: {{ data.monitored.length }}</p>
                    <p> Rate: 4.99</p>
                    <p> Amount: ${{ data.totalAmountMonitored }}</p>
                </div>


            </div>
        </div>
        <div class="table-responsive" v-if="!data.loadData">
            <table class="table caption-top table-light table-striped table-hover text-center">
                <caption class="text-center">
                    <p class="bg-dark text-white table-text h4 pb-2 pt-2 ">Monitoring Ending</p>
                </caption>
                <thead class="">
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Monitored End Date</th>


                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(billing, index) in data.deactivated" :key="index">
                        <td>
                            <i @click="showInfo(billing.userAccountID)" class='bx bx-error-circle'></i>
                        </td>
                        <td>
                            <router-link class="mx-1" :to="{
                                path: '/edit-client/' + billing.clientEmail + '/' + billing.franchise_ID,
                                query: {
                                    fromBilling: true,
                                    dateBilling: formatDate(billing.calendarDate),
                                    franchise_ID: billing.franchise_ID,
                                }
                            }">
                                {{ billing.firstName }} {{ billing.lastName }}

                            </router-link>
                        </td>

                        <td>{{ formatDate(billing.monitoredUntilDate) }} </td>

                    </tr>
                </tbody>
            </table>

        </div>
        <div class="row" v-if="!data.loadData">
            <div class="col">total# {{ data.deactivated.length }}</div>
            <div class="col">Rate:4.99</div>
            <div class="col"> Billed: {{ data.totalAmountDeactivated }}</div>
        </div>
        <div class="table-responsive" v-if="!data.loadData">
            <table class="table caption-top table-light table-striped table-hover text-center">
                <caption class="text-center">
                    <p class="bg-dark text-white table-text h4 pb-2 pt-2 ">Summary</p>
                </caption>
                <thead class="">
                    <tr>
                        <th>Total Monitored# {{ data.totalLocation }}</th>
                        <th>Total Billed: ${{ data.totalAmountAll }}</th>


                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style>
i .bx .bx-error-circle:hover {
    font-size: 1rem;
}
</style>